import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IUser } from '../../models';
import { ISystemEvent } from '../../models/events.model';
import { StorageService } from '../storage/storage.service';
import { UtilService } from '../util/util.service';
import { VariablesService } from './../variables/variables.service';
import * as dayjs from 'dayjs';
import { ApiService } from '../api/api.service';


declare let dataLayer: any[]; /**GTM Object */
declare let clevertap; /**Claver Tap event push */
declare let clarity;

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  public APIURL = environment.APP.BASE_URL;
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  ab = {
    abtestname: '',
    abvalue: ''
  };

  constructor(
    public http: HttpClient,
    private utilService: UtilService,
    private storageService: StorageService,
    private vars: VariablesService,
    private apiService: ApiService
  ) { }

  /**System Event Push */
  systemEventPush(data: ISystemEvent) {
    const payload = {
      eventName: data.eventName,
      event_type: data.event_type,
      page_name: data.page_name,
      event_type_id: data.event_type_id,
      info_1: data.info_1 || '',
      info_2: data.info_2 || '',
      info_3: data.info_3 || '',
      info_4: data.info_4 || '',
    };
    this.sendSystemEvent(payload).subscribe(_ => _);
  }
  /**Claver tab push
   * @param name Event Name
   * @param pushObject Data to be pushed to claver tap
  */
  async claverTapPush(name, pushObject) {
    pushObject = this.utilService.removeEmptyFromObject(pushObject);
    pushObject['Page Name'] = this.vars.pageName;
    pushObject['Device Type'] = this.vars.deviceType;
    pushObject['Page Version'] = this.utilService?.domain_details?.domain_name || '';
    const user: IUser = this.storageService.get('userdata');
    // console.log(user);
    if (user) {
      pushObject['Donor Name'] = user.full_name;
      pushObject['Email ID'] = user.email;
      pushObject['Identity'] = user.id;
      pushObject['Mobile'] = user.extension + user.phone_1;
    }
    if (pushObject.pageURL) {
      pushObject['Page URL'] = pushObject.pageURL;
      delete pushObject.pageURL;
    }


    if ((name === 'New Contribution' || name === 'New Contribution Step 3') && this.vars.fundraiser) {
      pushObject['Campaign Title'] = this.vars.fundraiser.title;
      pushObject['Truncated Campaign Title'] = this.vars.fundraiser.title.length > 20 ? this.vars.fundraiser.title.substr(0, 20).concat('...') : this.vars.fundraiser.title;

      if (this.vars?.fundraiser?.campaigner?.full_name) {
        pushObject['Campaigner Name'] = this.vars.fundraiser.campaigner.full_name;
      }
      if (this.vars?.basicInfo?.beneficiary_name) {
        pushObject['Beneficiary Name'] = this.vars.basicInfo.beneficiary_name;
      }
      if (this.vars?.fundraiser?.gallery?.[0]?.cdn_path) {
        pushObject['Campaign Image URL'] = this.vars.fundraiser.gallery[0].cdn_path;
      }

      if (this.utilService.isMobile()) {
        const mobileOS = this.utilService.getMobileOperatingSystem();
        if (mobileOS) {
          pushObject['Mobile Operating System'] = mobileOS.toLowerCase();
        }
      }
    }

    Object.assign(pushObject, this.utilService.utm_url_obj);

    // console.log('ct event = ', name);
    // console.log('ct data', pushObject);

    try {
      if (this.utilService?.domain_details?.domain_name !== 'childhelpfoundation') {
        if (typeof clevertap === 'undefined') {
          await this.isScriptReady('ct_loaded');
          (<any>window).clevertap.event.push(name, pushObject);
        } else {
          clevertap.event.push(name, pushObject);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  async claverProfilePush(pushObject) {
    const phone: string = pushObject?.Site?.Mobile || '';
    const countryCode = '+91';
    if (phone && phone.includes(countryCode)) {
      const phoneArr = [...phone.replace('+91', '')];
      const uniqueStringValues = new Set(phoneArr);
      if (uniqueStringValues.size === 1 || !(/^((\+)?(91))?([0])?([6-9][0-9]{9})$/).test(phone)) {
        const excludeCalls = ['+918657098283', '+919324932708', '+919324932709', '+919324932710', '+919321918898'];
        excludeCalls.forEach(val => {
          pushObject['Site'][val] = 'No';
        });
      }
    }
    pushObject['Site']['MSG-push'] = true;
    pushObject['Site']['MSG-whatsapp'] = true;
    try {
      if (this.utilService?.domain_details?.domain_name !== 'childhelpfoundation') {
        if (typeof clevertap === 'undefined') {
          await this.isScriptReady('ct_loaded');
          (<any>window).clevertap.onUserLogin.push(pushObject);
        } else {
          clevertap.onUserLogin.push(pushObject);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  async pushNotificationCL() {
    // try {
    //   const payload = {
    //     'titleText': 'Would you like to receive notifications from Ketto?',
    //     'bodyText': 'We promise to send you only relevant updates',
    //     'okButtonText': 'Allow',
    //     'rejectButtonText': `Don't Allow`,
    //     'okButtonColor': '#01bfbd'
    //   };
    //   if (typeof clevertap === 'undefined') {
    //     await this.isScriptReady('ct_loaded');
    //     (<any>window).clevertap.notifications.push(payload);
    //   } else {
    //     clevertap.notifications.push(payload);
    //   }
    // } catch (e) {
    //   console.log(e);
    // }
  }
  /**GTM Push Events
   * @param data Data to be pushed in GTM
   */
  async gtmPush(data) {
    if (typeof window !== 'undefined') {
      data['x-fb-event_id'] = +(dayjs().valueOf().toString() + Math.floor(Math.random() * 10000000).toString());
      const user: IUser = this.storageService.get('userdata');
      if (user) {
        data['user_data'] = {
          email_address: user.email,
          phone_number: user.phone_1
        };
      }
      if (typeof dataLayer === 'undefined') {
        await this.isScriptReady('gtm_loaded');
        (<any>window).dataLayer.push(data);
      } else {
        dataLayer.push(data);
      }
    }
  }

  async clarityEventsPush(data) {
    if (typeof window !== 'undefined') {
      if (typeof clarity === 'undefined') {
        await this.isScriptReady('clarity_loaded');
        (<any>window).dataLayer.push(data);
      } else {
        clarity("set", "EntityId", `${data}`);
      }
    }
  }
  /**
   * Send the data to system envent service
   * @param data event object to push in GTM
   */
  sendSystemEvent(data) {
    if (typeof window === 'undefined') {
      return throwError(() => '');
    }
    if (typeof window !== 'undefined') {
      data['referrer_page'] = document.referrer || '';
      data['device'] = this.vars.deviceType;
      Object.assign(data, this.utilService.utm_url_obj);
    }
    const user: IUser = this.storageService.get('userdata');
    if (user) {
      data['entity_id'] = user.id;
    }
    if (!data?.page_name) {
      data['page_name'] = this.vars.pageName;
    }
    const abVars = this.setZohoABData();
    if (abVars) {
      data = {...data, ...abVars};
    }
    const url = environment.APP.CORE_API + environment.APP.API_URLS.SYSTEM_EVENT_GTM;
    const options = {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }),
      params: data
    };

    // new system event
    this.http.post('/actions/collect', data).subscribe(_ => _);

    return this.http.get(url, options);
  }

  async onLoadSystemEvent(payload) {
    if (this.utilService.isBrowser) {
      this.sendSystemEvent(payload).subscribe(_ => _);
    }
  }

  /** User interaction events of GTM */
  userGTMEvents(data: ISystemEvent) {
    const event = {
      event: data.event,
      event_category: data.event_category,
      event_action: data.event_action,
      event_label: data.event_label.toLowerCase()
    };
    this.gtmPush(event);
  }

  isScriptReady(name) {
    return new Promise((resolve, reject) => {
      try {
        if (typeof document !== 'undefined') {
          document.addEventListener(name, () => resolve(true));
        }
      } catch (error) {
        reject(false);
      }
    });
  }

  sendLead(data: Object) {
    const user: IUser = this.storageService.get('userdata');
    const payload: any = {
      name: user?.full_name?.trim(),
      email: user.email,
      extension: user.extension,
      phone: user.phone_1,
      page: this.vars.pageName,
      mail_to: this.vars.leadMailTo,
      user_id: user.id,
      ...data
    };
    this.apiService.post(environment.APP.API_URLS.GET_LEAD, payload, 'false').subscribe(_ => _);
  }

  sendImpactLead(data?: Object) {
    const user: IUser = this.storageService.get('userdata');
    const payload: any = {
      page_name: this.vars.pageName,
      ...data,
      ...this.utilService.utm_url_obj
    };
    this.apiService.post(environment.APP.API_URLS.IMPACT_PAGE_LEAD(user?.id), payload, 'false').subscribe(_ => _);
  }

  setZohoABData() {
    const zps = (<any>window)?.zps?.getAllABExperiments()[0];
    if (zps?.experiment_name && !this.ab?.abtestname) {
      return {
        ab_testname: zps?.experiment_name || '',
        ab_value: zps?.variation_name || 0
      };
    }
  }

}
